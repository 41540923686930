.hero__content h2 {
	font-weight: 600;
	line-height: 55px;
}

.hero__content {
	padding-top: 80px;
}
.search input {
	border: none;
	outline: none;
	padding: 7px 10%;
}

.search {
	width: 60%;
	border: 1px solid #17bf9d44;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 50px;
	margin-top: 20px;
}

@media (max-width: 767px) {
	/* Align text to center. */
	.text-xs-center {
		text-align: center;
	}
	.hero__content {
		padding-top: 0px;
	}
}
